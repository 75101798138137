<template>
  <v-card>
    <b-overlay :show="loading" variant="white" rounded="sm">
      <v-card-title class="d-block mb-4">
        Información General del Proyecto
        <v-btn
          class="bg-primary text-white float-end"
          v-if="writeable"
          @click="abrirModalEditar"
        >
          Editar Datos
        </v-btn>
      </v-card-title>
      <v-card-text>
        <b-alert
          :show="segundosMensajeActualizacion"
          dismissible
          :variant="mensaje.variant"
          @dismissed="segundosMensajeActualizacion = 0"
          @dismiss-count-down="actualizarSegundosMensajeActualizacion"
        >
          {{ mensaje.texto }}
        </b-alert>

        <div class="row">
          <div class="col-12 col-md-3">
            <img
              :src="urlImagen(lugarInstalacion.imagen)"
              class="w-100 h-100 object-fit-cover"
            />
          </div>
          <div class="col-12 col-md-9">
            <div class="row">
              <div class="col-6 col-md-4">
                <div>
                  <i class="fas fa-project-diagram icono"></i>
                  <div class="contenedor-textos">
                    <div class="text-muted">
                      Código Proyecto
                    </div>
                    <div>
                      {{ lugarInstalacion.codigo_proyecto }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-4">
                <div>
                  <i class="fas fa-layer-group icono"></i>
                  <div class="contenedor-textos">
                    <div class="text-muted">
                      Código Lugar Instalación
                    </div>
                    <div>
                      {{ lugarInstalacion.codigo_lugar_instalacion }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-4">
                <div>
                  <i class="fas fa-map-marked-alt icono"></i>
                  <div class="contenedor-textos">
                    <div class="text-muted">
                      Dirección
                    </div>
                    <div>
                      {{ lugarInstalacion.direccion }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-4">
                <div>
                  <i class="fas fa-city icono"></i>
                  <div class="contenedor-textos">
                    <div class="text-muted">
                      Comuna
                    </div>
                    <div>
                      {{ lugarInstalacion.nombre_comuna }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-4">
                <div>
                  <i class="fas fa-map-pin icono"></i>
                  <div class="contenedor-textos">
                    <div class="text-muted">
                      Region
                    </div>
                    <div>
                      {{ lugarInstalacion.nombre_region }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-4">
                <div>
                  <i class="fas fa-map-marker icono"></i>
                  <div class="contenedor-textos">
                    <div class="text-muted">
                      Latitud
                    </div>
                    <div>
                      {{ lugarInstalacion.latitud }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-6 col-md-4">
                <div>
                  <i class="fas fa-map-marker-alt icono"></i>
                  <div class="contenedor-textos">
                    <div class="text-muted">
                      Longitud
                    </div>
                    <div>
                      {{ lugarInstalacion.longitud }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-4">
                <div>
                  <i class="fas fa-solar-panel icono"></i>
                  <div class="contenedor-textos">
                    <div class="text-muted">
                      Tablero personalizado
                    </div>
                    <div>
                      {{
                        proyectoUsuario && proyectoUsuario.panel_especial
                          ? proyectoUsuario.panel_especial
                          : "Sin Tablero"
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card-text>
    </b-overlay>

    <b-modal
      v-model="mostrarModal"
      title="Actualizar Datos Generales"
      hide-footer
      size="lg"
    >
      <loading-flux :value="loadingSubmit"></loading-flux>

      <form @submit.prevent="submit">
        <div class="row">
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="nombreProyecto">Nombre Lugar Instalación</label>
              <input
                id="nombreProyecto"
                v-model="$v.form.nombreProyecto.$model"
                type="text"
                class="form-control"
                placeholder="Nombre Lugar Instalación"
                :class="{
                  'is-invalid': submitted && $v.form.nombreProyecto.$invalid,
                }"
              />
              <div
                v-if="submitted && $v.form.nombreProyecto.$invalid"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>
          <div class="col-lg-6" v-if="false">
            <div class="mb-3">
              <label for="montoProyecto">Monto</label>
              <input
                id="montoProyecto"
                v-model="$v.form.montoProyecto.$model"
                type="number"
                step="0.001"
                class="form-control"
                placeholder="Monto Proyecto"
                :class="{
                  'is-invalid': $v.form.montoProyecto.$invalid && submitted,
                }"
              />

              <div
                class="invalid-feedback"
                v-if="submitted && $v.form.montoProyecto.$invalid"
              >
                El campo debe ser numérico
              </div>
            </div>
          </div>
          <div class="col-lg-6" v-if="false">
            <div class="mb-3">
              <label for="tipoProyecto">Tipo Proyecto</label>
              <multiselect
                v-model="$v.form.tipoProyecto.$model"
                :options="tipoProyectos"
                placeholder="Seleccionar Tipo Proyecto"
                value="tipo_proyecto_id"
                label="nombre_tipo_proyecto"
                :searchable="true"
                :loading="tipoProyectosCargando"
                open-direction="bottom"
                class="helo"
                :class="{
                  'is-invalid': submitted && $v.form.tipoProyecto.$invalid,
                }"
              ></multiselect>
              <div
                v-if="submitted && $v.form.tipoProyecto.$invalid"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>
          <div class="col-lg-6" v-if="false">
            <div class="mb-3">
              <label for="estado">Estado*</label>
              <multiselect
                v-model="$v.form.estado.$model"
                :options="estados"
                placeholder="Seleccionar Estado"
                value="value"
                label="label"
                :searchable="true"
                class="helo"
                :class="{
                  'is-invalid': submitted && $v.form.estado.$invalid,
                }"
              ></multiselect>
              <div
                v-if="submitted && $v.form.estado.$invalid"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>
          <div class="col-lg-6" v-if="false">
            <div class="mb-3">
              <label for="etapaProyecto">Etapa Proyecto</label>
              <multiselect
                v-model="$v.form.etapaProyecto.$model"
                :options="etapasProyecto"
                placeholder="Seleccionar Etapa"
                value="etapa_proyecto_id"
                label="nombre_etapa_proyecto"
                :searchable="true"
                :loading="etapasProyectoCargando"
                class="helo"
                :class="{
                  'is-invalid': submitted && $v.form.etapaProyecto.$invalid,
                }"
              ></multiselect>
              <div
                v-if="submitted && $v.form.etapaProyecto.$invalid"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>
          <!-- <div class="col-lg-6">
            <div class="mb-3">
              <label for="subEtapaProyecto">Sub Etapa Proyecto</label>
              <multiselect
                v-model="$v.form.subEtapaProyecto.$model"
                :options="subEtapasProyecto"
                placeholder="Seleccionar Sub Etapa"
                value="sub_etapa_proyecto_id"
                label="nombre_sub_etapa_proyecto"
                :searchable="true"
                :loading="subEtapasProyectoCargando"
                class="helo"
                :class="{
                  'is-invalid': submitted && $v.form.subEtapaProyecto.$invalid,
                }"
              ></multiselect>
              <div
                v-if="submitted && $v.form.subEtapaProyecto.$invalid"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div> -->
          <div class="col-lg-6" v-if="false">
            <div class="mb-3">
              <label for="tipoVenta">Tipo Venta</label>
              <multiselect
                v-model="$v.form.tipoVenta.$model"
                :options="tipoVentas"
                placeholder="Seleccionar Tipo Venta"
                value="tipo_venta_id"
                label="nombre_tipo_venta"
                :searchable="true"
                :loading="tipoVentasCargando"
                class="helo"
                :class="{
                  'is-invalid': submitted && $v.form.tipoVenta.$invalid,
                }"
              ></multiselect>
              <div
                v-if="submitted && $v.form.tipoVenta.$invalid"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="mb-3">
              <label for="direccion">Dirección</label>
              <input
                id="direccion"
                v-model="$v.form.direccion.$model"
                type="text"
                class="form-control"
                placeholder="Dirección"
                :class="{
                  'is-invalid': submitted && $v.form.direccion.$invalid,
                }"
              />
              <div
                v-if="submitted && $v.form.direccion.$invalid"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="mb-3">
              <label for="region">Region</label>
              <multiselect
                @input="obtenerComunasRegionProyecto()"
                v-model="$v.form.region.$model"
                :options="regiones"
                placeholder="Seleccionar Region"
                value="codigo"
                label="nombre"
                :searchable="true"
                :loading="regionesCargando"
                class="helo"
                :class="{
                  'is-invalid': submitted && $v.form.region.$invalid,
                }"
              ></multiselect>
              <div
                v-if="submitted && $v.form.region.$invalid"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="mb-3">
              <label for="comuna">Comuna</label>
              <multiselect
                v-model="$v.form.comuna.$model"
                :options="comunasRegion"
                placeholder="Seleccionar Comuna"
                value="codigo"
                label="nombre"
                :searchable="true"
                class="helo comuna"
                :loading="comunasRegionCargando"
                :class="{
                  'is-invalid': submitted && $v.form.comuna.$invalid,
                }"
              ></multiselect>
              <div
                v-if="submitted && $v.form.comuna.$invalid"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="mb-3">
              <label for="latitud">Latitud</label>
              <input
                id="latitud"
                v-model="$v.form.latitud.$model"
                type="text"
                class="form-control"
                placeholder="Latitud"
                :class="{
                  'is-invalid': submitted && $v.form.latitud.$invalid,
                }"
              />
              <div
                v-if="submitted && $v.form.latitud.$invalid"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="mb-3">
              <label for="longitud">Longitud</label>
              <input
                id="longitud"
                v-model="$v.form.longitud.$model"
                type="text"
                class="form-control"
                placeholder="Longitud"
                :class="{
                  'is-invalid': submitted && $v.form.longitud.$invalid,
                }"
              />
              <div
                v-if="submitted && $v.form.longitud.$invalid"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>
        </div>
        <div class="text-end mt-3">
          <b-button variant="light" @click="mostrarModal = false"
            >Cerrar</b-button
          >
          <b-button type="submit" variant="success" class="ms-1"
            >Actualizar</b-button
          >
        </div>
      </form>
    </b-modal>
  </v-card>
</template>

<script>
import {
  lugarInstalacionMethods,
  lugarInstalacionComputed,
} from "@/state/helpers";
import { tipoProyectoMethods, tipoProyectoComputed } from "@/state/helpers";
import { tipoVentaMethods, tipoVentaComputed } from "@/state/helpers";
import { etapaProyectoMethods, etapaProyectoComputed } from "@/state/helpers";
import {
  subEtapaProyectoMethods,
  subEtapaProyectoComputed,
} from "@/state/helpers";
import { localizacionMethods, localizacionComputed } from "@/state/helpers";
import { proyectoComputed } from "@/state/helpers";
import { required, numeric } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import { authMethods, authUsuarioComputed } from "@/state/helpers";
export default {
  name: "LugarDatosGenerales",
  mixins: [validationMixin],
  components: {
    Multiselect,
  },
  props: {
    writeable: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
      loadingSubmit: false,
      mensaje: { variant: "", texto: "" },
      segundos: 5,
      segundosMensajeActualizacion: 0,
      submitted: false,
      mostrarModal: false,
      lugarInstalacion: {},
      tipoProyectos: [],
      tipoVentas: [],
      etapasProyecto: [],
      subEtapasProyecto: [],
      regiones: [],
      comunasRegion: [],
      zonas: [
        { value: "Norte", label: "Norte" },
        { value: "Centro", label: "Centro" },
        { value: "Sur", label: "Sur" },
        { value: "Insular", label: "Insular" },
      ],
      estados: [
        { value: "Activo", label: "Activo" },
        { value: "Cerrado", label: "Cerrado" },
        { value: "Detenido", label: "Detenido" },
        { value: "Nuevo", label: "Nuevo" },
        { value: "Terminado", label: "Terminado" },
      ],

      tipoProyectosCargando: false,
      tipoVentasCargando: false,
      etapasProyectoCargando: false,
      subEtapasProyectoCargando: false,
      regionesCargando: false,
      comunasRegionCargando: false,

      form: {
        nombreProyecto: null,
        montoProyecto: null,
        tipoProyecto: null,
        tipoVenta: null,
        estado: null,
        etapaProyecto: null,
        subEtapaProyecto: null,
        direccion: null,
        region: null,
        comuna: null,
        latitud: null,
        longitud: null,
        zona: null,
      },
    };
  },
  validations: {
    form: {
      nombreProyecto: { required },
      montoProyecto: { numeric },
      tipoProyecto: {},
      tipoVenta: {},
      estado: {},
      etapaProyecto: {},
      subEtapaProyecto: {},
      direccion: {},
      region: {},
      comuna: {},
      latitud: {},
      longitud: {},
      zona: {},
    },
  },
  mounted() {
    this.codigoProyecto = this.$route.params.codigoProyecto;
    this.codigoLugarInstalacion = this.$route.params.codigoLugarInstalacion;
    this.obtenerLugarInstalacionProyecto();
  },
  methods: {
    ...lugarInstalacionMethods,
    ...tipoProyectoMethods,
    ...tipoVentaMethods,
    ...etapaProyectoMethods,
    ...subEtapaProyectoMethods,
    ...localizacionMethods,
    actualizarSegundosMensajeActualizacion(segundosMensajeActualizacion) {
      this.segundosMensajeActualizacion = segundosMensajeActualizacion;
    },
    abrirModalNuevo() {
      this.resetForm();
      this.esEditar = false;
      this.submitted = false;
      this.mostrarModal = true;
    },

    abrirModalEditar() {
      // this.tipoDocumentoSeleccionado = datos;
      this.resetForm();

      this.obtenerTipoProyectosProyecto();
      this.obtenerTipoVentasProyecto();
      this.obtenerEtapaProyectoProyecto();
      this.obtenerSubEtapaProyectoProyecto();
      this.obtenerRegionesProyecto();

      this.$v.form.nombreProyecto.$model = this.lugarInstalacion.nombre_proyecto;
      this.$v.form.montoProyecto.$model = this.lugarInstalacion.monto_proyecto;
      this.$v.form.latitud.$model = this.lugarInstalacion.latitud;
      this.$v.form.longitud.$model = this.lugarInstalacion.longitud;

      this.$v.form.direccion.$model = this.lugarInstalacion.direccion;
      this.$v.form.estado.$model = {
        value: this.lugarInstalacion.estado,
        label: this.lugarInstalacion.estado,
      };
      this.$v.form.zona.$model = {
        value: this.lugarInstalacion.zona,
        label: this.lugarInstalacion.zona,
      };

      this.esEditar = true;
      this.submitted = false;
      this.mostrarModal = true;
    },
    obtenerLugarInstalacionProyecto() {
      this.loading = true;
      this.obtenerLugarInstalacion({
        codigoProyecto: this.codigoProyecto,
        codigoLugarInstalacion: this.codigoLugarInstalacion,
      })
        .then((res) => {
          this.lugarInstalacion = res.body;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    obtenerTipoProyectosProyecto() {
      this.tipoProyectosCargando = true;
      this.obtenerTipoProyectos()
        .then((res) => {
          this.tipoProyectos = res.body;
          this.$v.form.tipoProyecto.$model = {
            tipo_proyecto_id: this.lugarInstalacion.tipo_proyecto_id,
            nombre_tipo_proyecto: this.lugarInstalacion.nombre_tipo_proyecto,
          };
          this.tipoProyectosCargando = false;
        })
        .catch((error) => {
          this.tipoProyectosCargando = false;
        });
    },
    obtenerTipoVentasProyecto() {
      this.tipoVentasCargando = true;
      this.obtenerTipoVentas()
        .then((res) => {
          this.tipoVentas = res.body;
          this.$v.form.tipoVenta.$model = {
            tipo_venta_id: this.lugarInstalacion.tipo_venta_id,
            nombre_tipo_venta: this.lugarInstalacion.nombre_tipo_venta,
          };
          this.tipoVentasCargando = false;
        })
        .catch((error) => {
          this.tipoVentasCargando = false;
        });
    },
    obtenerEtapaProyectoProyecto() {
      this.etapasProyectoCargando = true;
      this.obtenerEtapasProyecto()
        .then((res) => {
          this.etapasProyecto = res.body;

          this.$v.form.etapaProyecto.$model = {
            etapa_proyecto_id: this.lugarInstalacion.etapa_proyecto_id,
            nombre_etapa_proyecto: this.lugarInstalacion.nombre_etapa_proyecto,
          };
          this.etapasProyectoCargando = false;
        })
        .catch((error) => {
          this.etapasProyectoCargando = false;
        });
    },
    obtenerSubEtapaProyectoProyecto() {
      this.SubEtapasProyectoCargando = true;
      this.obtenerSubEtapasProyecto()
        .then((res) => {
          this.subEtapasProyecto = res.body;
          if (this.lugarInstalacion.sub_etapa_proyecto_id != null) {
            this.$v.form.subEtapaProyecto.$model = {
              sub_etapa_proyecto_id: this.lugarInstalacion
                .sub_etapa_proyecto_id,
              nombre_sub_etapa_proyecto: this.lugarInstalacion
                .nombre_sub_etapa_proyecto,
            };
          }

          this.subEtapasProyectoCargando = false;
        })
        .catch((error) => {
          this.subEtapasProyectoCargando = false;
        });
    },
    obtenerRegionesProyecto() {
      this.regionesCargando = true;
      this.obtenerRegiones()
        .then((res) => {
          this.regiones = res.body;
          this.$v.form.comuna.$model = {
            codigo: this.lugarInstalacion.codigo_comuna,
            nombre: this.lugarInstalacion.nombre_comuna,
          };
          this.$v.form.region.$model = {
            codigo: this.lugarInstalacion.codigo_region,
            nombre: this.lugarInstalacion.nombre_region,
          };

          this.regionesCargando = false;
        })
        .catch((error) => {
          this.regionesCargando = false;
        });
    },
    obtenerComunasRegionProyecto() {
      this.comunasRegionCargando = true;
      this.$v.form.comuna.$model = null;
      let codigoRegion = this.$v.form.region.$model.codigo;
      this.obtenerComunasRegion(codigoRegion)
        .then((res) => {
          this.comunasRegion = res.body;
          this.comunasRegionCargando = false;
        })
        .catch((error) => {
          this.comunasRegionCargando = false;
        });
    },
    abrirMenu() {
      this.$bvModal.show("modal-menu-lugar-instalacion");
    },
    submit(e) {
      this.loadingSubmit = true;
      this.submitted = true;
      this.$v.form.$touch();

      if (this.$v.form.$invalid) {
        this.loadingSubmit = false;
        return;
      }

      const codigo_lugar_instalacion = this.lugarInstalacion
        .codigo_lugar_instalacion;
      const codigo_proyecto = this.lugarInstalacion.codigo_proyecto;

      const nombre_proyecto = this.$v.form.nombreProyecto.$model;
      const monto_proyecto =
        this.$v.form.montoProyecto.$model != null
          ? this.$v.form.montoProyecto.$model
          : null;
      const tipo_proyecto_id =
        this.$v.form.tipoProyecto.$model != null
          ? this.$v.form.tipoProyecto.$model.tipo_proyecto_id
          : null;
      const nombre_tipo_proyecto =
        this.$v.form.tipoProyecto.$model != null
          ? this.$v.form.tipoProyecto.$model.nombre_tipo_proyecto
          : null;

      const tipo_venta_id =
        this.$v.form.tipoVenta.$model != null
          ? this.$v.form.tipoVenta.$model.tipo_venta_id
          : null;
      const nombre_tipo_venta =
        this.$v.form.tipoVenta.$model != null
          ? this.$v.form.tipoVenta.$model.nombre_tipo_venta
          : null;

      const estado =
        this.$v.form.estado.$model != null
          ? this.$v.form.estado.$model.value
          : null;
      const etapa_proyecto_id =
        this.$v.form.etapaProyecto.$model != null
          ? this.$v.form.etapaProyecto.$model.etapa_proyecto_id
          : null;
      const nombre_etapa_proyecto =
        this.$v.form.etapaProyecto.$model != null
          ? this.$v.form.etapaProyecto.$model.nombre_etapa_proyecto
          : null;
      const sub_etapa_proyecto_id = "5a2a973b690144b3a2ca19ec9e2931ef";
      const nombre_sub_etapa_proyecto = "Nuevo Proyecto";
      const direccion = this.$v.form.direccion.$model;
      const codigo_comuna = this.$v.form.comuna.$model.codigo;
      const nombre_comuna = this.$v.form.comuna.$model.nombre;

      const codigo_region = this.$v.form.region.$model.codigo;
      const nombre_region = this.$v.form.region.$model.nombre;
      let latitud =
        this.$v.form.latitud.$model != null
          ? this.$v.form.latitud.$model
          : null;
      let longitud =
        this.$v.form.longitud.$model != null
          ? this.$v.form.longitud.$model
          : null;
      const zona =
        this.$v.form.zona.$model != null
          ? this.$v.form.zona.$model.value
          : null;

      latitud = latitud != null ? latitud.toString().replace(",", ".") : null;
      longitud =
        longitud != null ? longitud.toString().replace(",", ".") : null;

      if (this.esEditar) {
        this.actualizarLugarInstalacion({
          codigo_lugar_instalacion,
          codigo_proyecto,
          nombre_proyecto,
          monto_proyecto,
          tipo_proyecto_id,
          nombre_tipo_proyecto,
          estado,
          tipo_venta_id,
          nombre_tipo_venta,
          etapa_proyecto_id,
          nombre_etapa_proyecto,
          sub_etapa_proyecto_id,
          nombre_sub_etapa_proyecto,
          direccion,
          codigo_comuna,
          nombre_comuna,
          codigo_region,
          nombre_region,
          latitud,
          longitud,
          zona,
        })
          .then((res) => {
            if (res.status == 202) {
              this.mensaje.variant = "danger";
              this.mensaje.texto = "Ha ocurrido un error, intente nuevamente";
            }

            if (res.status == 204) {
              this.mensaje.variant = "success";
              this.mensaje.texto =
                "Lugar Instalación actualizado correctamente!!!";
              this.mostrarModal = false;
            }

            this.segundosMensajeActualizacion = this.segundos;
            this.obtenerLugarInstalacionProyecto();
            this.loadingSubmit = false;
          })
          .catch((error) => {
            this.mensaje.variant = "danger";
            this.mensaje.texto = "Ha ocurrido un error, intente nuevamente";
            this.mostrarModal = false;
            this.segundosMensajeActualizacion = this.segundos;
            this.loadingSubmit = false;
          });
      } else {
        this.crearLugarInstalacion({
          codigo_lugar_instalacion,
          codigo_proyecto,
          nombre_proyecto,
          monto_proyecto,
          tipo_proyecto_id,
          nombre_tipo_proyecto,
          estado,
          tipo_venta_id,
          nombre_tipo_venta,
          etapa_proyecto_id,
          nombre_etapa_proyecto,
          sub_etapa_proyecto_id,
          nombre_sub_etapa_proyecto,
          direccion,
          codigo_comuna,
          nombre_comuna,
          codigo_region,
          nombre_region,
          latitud,
          longitud,
          zona,
        })
          .then((res) => {
            if (res.status == 201) {
              this.mensaje.variant = "success";
              this.mensaje.texto = "Lugar Instalación creado correctamente!!!";
              this.mostrarModal = false;
            }

            if (res.status == 208) {
              this.mensaje.variant = "danger";
              this.mensaje.texto = "El lugar instalación ya existe!!!";
            }

            this.segundosMensajeActualizacion = this.segundos;
            this.obtenerLugarInstalacionProyecto();
            this.loadingSubmit = false;
          })
          .catch((error) => {
            this.mensaje.variant = "danger";
            this.mensaje.texto = "Ha ocurrido un error, intente nuevamente";

            this.segundosMensajeActualizacion = this.segundos;
            this.loadingSubmit = false;
          });
      }

      this.submitted = false;
    },
    resetForm() {
      this.form = {
        nombreProyecto: null,
        montoProyecto: null,
        tipoProyecto: null,
        tipoVenta: null,
        estado: null,
        etapaProyecto: null,
        subEtapaProyecto: null,
        direccion: null,
        region: null,
        comuna: null,
        latitud: null,
        longitud: null,
        zona: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    urlImagen(imagen) {
      // if (imagen == null) {
      return `${process.env.VUE_APP_URL_IMAGENES_LUGARES_INSTALACION}lugarInstalacion.jpg`;
      // }
      // return `${process.env.VUE_APP_URL_IMAGENES_LUGARES_INSTALACION}${imagen}`;
    },
    eliminarLugarInstalacionProyecto(item) {
      const mensajeEliminar = Swal.mixin({
        customClass: {
          title: "font-size-18",
          confirmButton: "btn btn-danger ms-2",
          cancelButton: "btn btn-gris-claro",
        },
        buttonsStyling: false,
      });
      mensajeEliminar
        .fire({
          title: "Esta seguro que desea eliminar el registro?",
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Eliminar",
          cancelButtonText: "Cancelar",
          allowOutsideClick: false,
          reverseButtons: true,
        })
        .then((result) => {
          if (result.value) {
            this.eliminarLugarInstalacion(item.tipo_documento_id)
              .then((res) => {
                if (res.status == 202) {
                  this.mensaje.variant = "danger";
                  this.mensaje.texto =
                    "Ha ocurrido un error, intente nuevamente";
                }

                if (res.status == 204) {
                  this.mensaje.variant = "success";
                  this.mensaje.texto =
                    "Lugar Instalación eliminado correctamente!!!";
                  if (this.guardarCerrar) {
                    this.mostrarModal = false;
                  }
                }
                this.segundosMensajeActualizacion = this.segundos;
                this.obtenerLugarInstalacionsLocal();
              })
              .catch((error) => {
                this.mensaje.variant = "danger";
                this.mensaje.texto =
                  "No se ha eliminado el registro, intente nuevamente";
                this.mostrarModal = false;
                this.segundosMensajeActualizacion = this.segundos;
              });
          }
        });
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("es-CL", {
          style: "currency",
          currency: "CLP",
        });
      return;
    },
  },
  computed: {
    ...proyectoComputed,
    ...authUsuarioComputed,
    // ...lugarInstalacionComputed,
  },
};
</script>
<style scoped>
@media (max-width: 480px) {
  .d-flex {
    flex-direction: column !important;
  }
  .col-auto {
    display: block !important;
    width: 100% !important;
  }
}
</style>
