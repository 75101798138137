<template>
  <v-container class="mx-0 mx-auto">
    <v-row>
      <PageHeader :title="title" :items="items" />
      <v-col cols="12">
        <h5 class="card-title-desc mt-30-576 mt-30-1024px">
          {{ lugarInstalacion.nombre_proyecto }} - {{ lugarInstalacion.codigo_proyecto }} -
          {{ lugarInstalacion.codigo_lugar_instalacion }}
        </h5>
      </v-col>
      <v-col cols="12">
        <LugarInstalacionComponente
          v-if="rolModuloPermiso.lectura"
          :writeable="rolModuloPermiso.escritura == 1"
          :showTitle="false"
        />
      </v-col>
      <v-col cols="12">
        <v-sheet height="40">
          <Menu :lugarInstalacion="lugarInstalacion" />
        </v-sheet>
      </v-col>
      <v-col>
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Menu from '@/components/widgets/menu/MenuWidget'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import LugarInstalacionComponente from '@/components/proyectos/lugarInstalacion/LugarInstalacionComponent.vue'
import { permiso } from '@/helpers/authservice/obtener-permisos'
import { authUsuarioComputed, lugarInstalacionMethods } from '@/state/helpers'
export default {
  name: 'LugarInstalacionDatosGeneralesView',
  page: {
    title: 'Lugar Instalación',
    meta: [
      {
        name: 'description',
        content: appConfig.description
      }
    ]
  },
  components: {
    Menu,
    PageHeader,
    LugarInstalacionComponente
  },
  data() {
    return {
      title: 'Datos Generales',
      items: [
        {
          text: 'Proyectos',
          href: `/proyectos/proyectos`
        },
        {
          text: 'Lugares Instalación',
          href: `/proyectos/lugaresInstalacion/${this.$route.params.codigoProyecto}`
        },
        {
          text: 'Datos Generales',
          active: true
        }
      ],
      lugarInstalacion: {},
      rolModuloPermiso: {},
      codigoProyecto: null,
      codigoLugarInstalacion: null
    }
  },
  mounted() {
    this.codigoProyecto = this.$route.params.codigoProyecto
    this.codigoLugarInstalacion = this.$route.params.codigoLugarInstalacion
    let permisos = permiso.obtenerPermisos(this.user, 'lugarInstalacion')
    this.rolModuloPermiso = permisos
    this.obtenerLugarInstalacionProyecto()
  },
  computed: {
    ...authUsuarioComputed
  },
  methods: {
    ...lugarInstalacionMethods,
    obtenerLugarInstalacionProyecto() {
      this.loading = true
      this.obtenerLugarInstalacion({
        codigoProyecto: this.codigoProyecto,
        codigoLugarInstalacion: this.codigoLugarInstalacion
      })
        .then((res) => {
          this.lugarInstalacion = res.body
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
        })
    }
  }
}
</script>
